
























































import Vue from 'vue'

export default Vue.extend({
  props: {
    showNoVatModal: {
      type: Boolean,
      default: false,
    },
    contactVatNumber: {
      type: String,
      default: null,
    },
    isOpaque: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      noVatType: null as string | null,
      noVatTypes: ['intracom', 'export', 'other'],
      warning: '',
      buttonIsDisabled: false,
    }
  },
  watch: {
    showNoVatModal(newVal) {
      this.showModal = newVal
    },
  },
  methods: {
    closeNoVatModal() {
      this.noVatType = null
      this.showModal = false
      this.submit()
    },
    updateWarningNoVatType() {
      this.warning = ''
      if (this.noVatType === 'intracom' && !this.contactVatNumber) {
        this.warning = 'Numéro de TVA intracommunautaire non fourni.'
        this.buttonIsDisabled = true
      } else {
        this.buttonIsDisabled = false
      }
    },
    getNoVatTypeLabel(type: string): string {
      if (type === 'other') {
        return 'Autre'
      }
      return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
    },
    noSubmit() {
      return false
    },
    submit() {
      this.$emit('submit', this.noVatType)
      this.noVatType = null
    },
  },
})
